import React from 'react'
import CheckIcon from './icons/CheckIcon';
// import CrossIcon from './icons/CrossIcon';

function FeatureList({ features }) {
  const renderFeatures = features => features.map((feature, index) => {
    return (
      //TODO: Ver bien lo del id ya que no hay validacion respecto al nombre del feature (se podria repetir)
      <li key={index} className='flex items-center space-x-3'>
        {feature.associated && <CheckIcon className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" />}
        <span>{feature.name}</span>
      </li>
    );
  });

  return (
    <ul className="mt-4 mb-8 space-y-4 text-left">
      {renderFeatures(features)}
    </ul>
  )
}

export default FeatureList;
