import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomePage from './pages/HomePage';
import ApplicationPage from './pages/ApplicationPage';
import MercadoPagoPlanPage from './pages/MercadoPagoPlanPage';
import SubscriptionSuccessPage from './pages/SubscriptionSuccessPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App>
      <Routes>
      <Route path="/" Component={HomePage} />
        <Route path="/application/:id" Component={ApplicationPage} />
        <Route path="/plan/:id" Component={MercadoPagoPlanPage} />
        <Route path="/subscription-success/:id" Component={SubscriptionSuccessPage} />
        <Route path="**" Component={HomePage} />
      </Routes>
    </App>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
