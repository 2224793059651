import { Button } from 'flowbite-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
  const navigate = useNavigate()

  const goToApplications = () => navigate('/application/65d5028b8914a6808b715356')

  return (
    <div>
      <h1>Home</h1>
      <Button onClick={goToApplications}>Ir a App</Button>
    </div>
  )
}

export default HomePage
