import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import PricingCard from '../components/PricingCard';
import { NEST_SERVER_URL } from '../config';

function ApplicationPage() {
  const { id } = useParams();

  const [application, setApplication] = useState(null);

  useEffect(() => {
    const getApplication = async (id) => {
      const [{ data: application }, { data: plans }] = await Promise.all([
        axios.get(`${NEST_SERVER_URL}/applications/${id}`),
        axios.get(`${NEST_SERVER_URL}/plans?applicationId=${id}&status=active`)
      ]);

      setApplication({
        ...application,
        plans
      });

    };
    
    getApplication(id);
    
  }, [id]);
  
  if(application){
    console.log(application.plans)
  }

  const renderPlans = plans => plans.data.map((plan, index) => {
    //TODO: Validar que plans.data no este vacio si no buscar resolucion en caso contrario
    return (
      <PricingCard
        key={index}
        title={plan.name}
        description={plan.description}
        currencyId={plan.currencyId}
        price={plan.transactionAmount}
        period={plan.period}
        features={plan.features}
        anchorText="Elegir Plan"
        anchorLink={`/plan/${plan._id}`}
      />
    );
  });

  return (
    <div>
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{application && application.name}</h2>
        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">{application && application.description}</p>
      </div>
      <div className="flex flex-wrap justify-center gap-6 lg:gap-8 xl:gap-10">
        {application && renderPlans(application.plans)}
      </div>
    </div>
  )
}

export default ApplicationPage;
