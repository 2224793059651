import { Button } from "flowbite-react"
import { useNavigate } from 'react-router-dom';
import { redirect } from "react-router-dom";

const SubscriptionSuccessPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // TODO: Ver luego de pasar la URL del backoffice de la app suscrita de una forma correcta con react
    window.location = 'https://tour-bind.com/';
  };

  return (
    <div>
      Enhorabuena te has suscripto correctamente!!!
      <Button className="mt-3 outline outline-2 outline-offset-2 outline-green-500 outline bg-white-100 text-black-400 enabled:hover:bg-green-500" onClick={handleClick}>Ir a aplicación</Button>
    </div>
  )
}

export default SubscriptionSuccessPage
