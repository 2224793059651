import React from 'react'

function Price({ currencyId, price, period }) {
  return (
    <div className="flex items-baseline text-gray-900 dark:text-white">
      <span className="text-3xl font-semibold">{currencyId}</span>
      <span className="text-5xl font-extrabold tracking-tight">{price}</span>
      {period && <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/{period}</span>}
    </div>
  )
}

export default Price;
