import React from 'react';

function App({ children }) {
  return (
    <div className="dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        {children}
      </div>
    </div>
  );
}

export default App;
