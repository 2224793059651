import React, { useEffect, useState } from 'react';

import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card } from 'flowbite-react';
import FeatureList from '../components/FeatureList';
import Price from '../components/Price';
import BackArrowIcon from '../components/icons/BackArrowIcon';
import { NEST_SERVER_URL, CREDENTIALS_MP, } from '../config';
import ClientForm from '../components/ClientForm';

//Public Key Vendedor Postaprueba PRODUCCION
initMercadoPago(CREDENTIALS_MP.public_key, CREDENTIALS_MP.locale);

//TODO: Agregar public key vendedor REAL PROD
// initMercadoPago('APP_USR-XXXXXXX-YYYY-YYYY-YYYY-XXXXXXXXXXX', { locale: 'es-AR' });


function MercadoPagoPlanPage() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [plan, setPlan] = useState(null);
  const [validateClientForm, setValidateClientForm] = useState(false);
  const [valuesClientForm, setValuesClientForm] = useState({});

  useEffect(() => {
    const getPlan = async (id) => {
      const { data } = await axios.get(`${NEST_SERVER_URL}/plans/${id}`);
      setPlan(data.data);
    };
    getPlan(id);
  }, [id]);

  const customization = {
    visual: {
      hidePaymentButton: true,
      style: {
        theme: 'bootstrap',
        formPadding: "0px",
      }
    },
    paymentMethods: {
      maxInstallments: 1
    }
  };

  const getValuesClientForm = (valuesClient) => {
    setValuesClientForm(valuesClient);
  }


  const onSubmit = async (formData) => {

    const payload = {
      planId: id,
      cardToken: formData.token,
      issuerId: formData.issuer_id,
      paymentMethodId: formData.payment_method_id,
      transactionAmount: formData.transaction_amount,
      payerEmail: formData.payer.email,
      payerIdType: formData.payer.identification.type,
      payerIdNumber: formData.payer.identification.number,
    };

    const { data } = await axios.post(`${NEST_SERVER_URL}/subscriptions`, payload);

    //TODO: Obtener la data del client si se lo requiere, para visualizar en la page de success subs
    await axios.post(`${NEST_SERVER_URL}/client`, { ...valuesClientForm, subscriptionId: data.data._id });

    navigateSuccessSubscription(data.data.planId);
  };

  const onReady = async () => {
    console.log('onReady');
  };

  const onError = async (error) => {
    console.error('onError', error);
    alert('Algo falló al crear la Suscripción');
  };

  const handleClick = (e) => {
    window.cardPaymentBrickController.getFormData().then(formData => onSubmit(formData)).catch(error => onError(error));
  };

  const handleReturn = () => {
    navigate(`/application/${plan.applicationId}`);
  }

  if (!plan) {
    return <div>Cargando...</div>
  }

  /**
   * Al suscribirse correctamente se nevage hacia la pagina correspondiente
   * @param planID Obtiene el id del plan
   */
  const navigateSuccessSubscription = async (planID) => {
    const { data } = await axios.get(`${NEST_SERVER_URL}/plans/${planID}`);
    const [, , , page, id] = data.data.backUrl.split('/');
    navigate(`/${page}/${id}`);
  }

  return (
    <Card>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-8 sm:gap-0">
        <div className="relative flex justify-center content-center items-center">
          <div>
            <h3 className="mb-4 text-2xl font-semibold">{plan.name}</h3>
            {plan.description && <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{plan.description}</p>}
            <Price currencyId={plan.currencyId} price={plan.transactionAmount} period={plan.period} />
            <FeatureList features={plan.features} />
            <Button onClick={handleReturn} color="light"><BackArrowIcon className="w-3 h-3 mr-2 text-gray-800 dark:text-white"></BackArrowIcon>Cambiar Plan</Button>
          </div>
          <div className="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100 lg:block"></div>
        </div>
        <hr className="lg:hidden my-4 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
        {
          !validateClientForm &&
          <ClientForm
            applicationId={plan.applicationId}
            setValidateClientForm={setValidateClientForm}
            getValuesClientForm={getValuesClientForm}
          />
        }
        {
          validateClientForm &&
          <div>
            <CardPayment
              className="relative"
              initialization={{ amount: plan.transactionAmount }}
              customization={customization}
              onSubmit={onSubmit}
              onReady={onReady}
              onError={onError}
            />
            <Button className="outline outline-2 outline-offset-2 outline-gray-200 outline bg-white-100 text-black-400 enabled:hover:bg-gray-200" onClick={handleClick} color="success" size="lg" fullSized>Suscribirme</Button>
          </div>
        }
      </div>
    </Card>
  )
}

export default MercadoPagoPlanPage;
