import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'flowbite-react';
import FeatureList from './FeatureList';
import Price from './Price';

function PricingCard({ title, description, currencyId, price, period, features, anchorText, anchorLink }) {
  const cardTheme = {
    root: {
      children: 'flex h-full flex-col justify-between gap-4 p-6'
    }
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(anchorLink);
  };

  // <Button onClick={handleClick} className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900">{anchorText}</Button>

  return (
    <Card theme={cardTheme}>
      <div className='p-5'>
        <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
        <hr />
        {description && <p className="py-3 font-light text-gray-500 sm:text-lg dark:text-gray-400">{description}</p>}
        <Price currencyId={currencyId} price={price} period={period} />
        <FeatureList features={features} />
      </div>
      <div>
        <Button className="outline outline-2 outline-offset-2 outline-gray-200 outline bg-white-100 text-black-400 enabled:hover:bg-gray-200" onClick={handleClick} fullSized>{anchorText}</Button>
      </div>
    </Card >
  )
}

export default PricingCard;
