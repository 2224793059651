import axios from 'axios';
import { NEST_SERVER_URL } from '../config';
import { Button } from "flowbite-react";
import React, { useState } from 'react';


const ClientForm = ({ applicationId, setValidateClientForm, getValuesClientForm }) => {
  const [valuesClientForm, setValuesClientForm] = useState({ applicationId });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValuesClientForm({
      ...valuesClientForm,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await axios.post(`${NEST_SERVER_URL}/client/validations-client`, valuesClientForm);
      getValuesClientForm(valuesClientForm)
      setValidateClientForm(true)
    } catch (e) {
      //TODO: Aplicar al formulario el error
      const { data } = e.response;
      alert(data.message)
      //TODO: Sacar de consola este error y enviarlo en un log
      console.error(data);
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-10 grid grid-cols-1 gap-y-2">
        <div className="sm:col-span-4 py-0">
          <label className="block text-sm font-medium leading-6 text-gray-900">Nombre</label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
              <input type="text" name="name" id="name" className="block flex-1 border-0 bg-transparent py-1.5 pl-1" onChange={handleChange} required autoComplete="false" />
            </div>
          </div>
        </div>
        <div className="sm:col-span-4 py-0">
          <label className="block text-sm font-medium leading-6 text-gray-900">Apellido</label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
              <input type="text" name="lastname" id="lastname" className="block flex-1 border-0 bg-transparent py-1.5 pl-1" onChange={handleChange} required autoComplete="false" />
            </div>
          </div>
        </div>
        <div className="sm:col-span-4 py-0">
          <label className="block text-sm font-medium leading-6 text-gray-900">Email</label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
              <input type="email" name="email" id="email" className="block flex-0 border-0 bg-transparent py-1.5 pl-1 w-full" onChange={handleChange} required />
            </div>
          </div>
        </div>
      </div>
      <Button className=" mt-6 outline outline-2 outline-offset-2 outline-gray-200 outline bg-white-100 text-black-400 enabled:hover:bg-gray-200" type="submit" color="success" size="lg" fullSized>Suscribirme</Button>
    </form>
  )
}

export default ClientForm;
